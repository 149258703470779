<template>
    <v-container class="pa-0">
        <v-row>
            <v-col cols="12" sm="6">
                <v-select
                    :items="supportedSourceLanguageNames"
                    :value="sourceLanguage"
                    :disabled="!supportedSourceLanguageNames.length"
                    label="Quellsprache"
                    @change="changeSourceLanguage($event)"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-select
                    :items="supportedTargetLanguageNames"
                    :value="targetLanguage"
                    :disabled="!supportedTargetLanguageNames.length"
                    label="Zielsprache"
                    @change="changeTargetLanguage($event)"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    computed: {
        supportedLanguages() {
            return this.$store.state.languageSelection.supportedLanguages;
        },
        supportedSourceLanguageNames() {
            return Object.values(this.supportedLanguages.source);
        },
        supportedTargetLanguageNames() {
            return Object.values(this.supportedLanguages.target);
        },
        sourceLanguage() {
            return this.supportedLanguages.source[this.$store.state.languageSelection.source];
        },
        targetLanguage() {
            return this.supportedLanguages.target[this.$store.state.languageSelection.target];
        }
    },
    methods: {
        changeSourceLanguage(value) {
            const language = this.$store.getters.supportedSourceLanguagesByName[value];
            this.$store.dispatch("setSourceLanguage", language);
        },
        changeTargetLanguage(value) {
            const language = this.$store.getters.supportedTargetLanguagesByName[value];
            this.useFormality = false;
            this.$store.commit("setUseFormality", false);
            this.$store.dispatch("setTargetLanguage", language);
            this.$emit('update:target-language', language);
        }
    }
}
</script>
