<template>
    <v-container class="pa-0">
        <v-row>
            <d-api-key-input/>
            <d-file-input/>
        </v-row>
        <d-languages-selection @update:target-language="$emit('update:target-language', $event)"/>
        <d-config />
        <d-action-buttons/>
    </v-container>
</template>

<script>
import DApiKeyInput from "@/components/DApiKeyInput";
import DFileInput from "@/components/DFileInput";
import DLanguagesSelection from "@/components/DLanguagesSelection";
import DActionButtons from "@/components/DActionButtons";
import DConfig from "@/components/DConfig";

export default {
    components: {
        DActionButtons,
        DFileInput,
        DLanguagesSelection,
        DApiKeyInput,
        DConfig,
    }
};
</script>
