<template>
    <v-col cols="6">
        <div class="d-flex">
            <v-file-input
                @change="parseFile"
                show-size
                label="XLIFF Datei"
                prepend-icon=""
                class="mr-3"
                :error-messages="fileError"
                :loading="loading"
                ref="fileInput"
            />
            <v-icon
                class="ma-auto hover pa-1"
                @click.passive="mimicClick"
            >
                mdi-paperclip
            </v-icon>
        </div>
    </v-col>
</template>

<script>
import { parseXliff } from "@/modules/parseXliff";

export default {
    data() {
        return {
            fileError: "",
            loading: false
        };
    },
    methods: {
        findXliffLanguage(languageCode, type) {

            languageCode = languageCode.toUpperCase();

            const supportedLanguages = this.$store.state.languageSelection.supportedLanguages[type];
            const codes = Object.keys(supportedLanguages);

            if (codes.includes(languageCode)) return languageCode;

            // eslint-disable-next-line no-unused-vars
            const [language, qualifier, ...rest] = languageCode.split("-");

            if (codes.includes(qualifier)) return qualifier;
            if (codes.includes(language)) return language;

            return undefined;
        },
        parseFile(file) {

            this.fileError = "";

            if (!file) return;

            this.loading = true;

            parseXliff(file)
                .then(value => {

                    this.$store.commit("setFileName", file.name);
                    this.$store.dispatch("setSourceLanguage", this.findXliffLanguage(value.source, "source"));
                    this.$store.dispatch("setTargetLanguage", this.findXliffLanguage(value.target, "target"));
                    this.$store.commit("setTransUnits", value.transUnits);
                    this.$store.commit("setFileObject", value.fileObject);
                    this.loading = false;
                })
                .catch(err => {

                    console.error(err);
                    this.fileError = "Die Datei konnte nicht geladen werden";
                    this.loading = false;
                });
        },
        mimicClick() {

            this.$refs.fileInput.$refs.input.click();
        },
    },
}
</script>
