<template>
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        @keydown.esc="$emit('input', $event)"
        max-width="500px"
    >
        <v-card>
            <v-toolbar
                class="mb-3 primary"
                flat
                dense
            >
                <v-toolbar-title :class="{ 'white--text': !darkMode, 'black--text': darkMode }">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="font-size: larger">{{ description }}</v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    @click="$emit('confirmDeletion', false)"
                    class="mr-2 white--text"
                    :class="cancelButtonClasses"
                >{{ cancelText }}</v-btn>
                <v-btn
                    @click="$emit('confirmDeletion', true)"
                    class="white--text"
                    :class="confirmButtonClasses"
                >{{ confirmText }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        cancelText: {
            type: String,
            default: "Abbrechen",
        },
        confirmText: {
            type: String,
            default: "Bestätigen",
        },
        invertButtonColors: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        darkMode() {
            return this.$store.state.darkMode;
        },
        cancelButtonClasses() {
            if(this.invertButtonColors) {
                return "red darken-4";
            }
            return "green darken-1";
        },
        confirmButtonClasses() {
            if(this.invertButtonColors) {
                return "green darken-1";
            }
            return "red darken-4";
        }
    },
};
</script>
