<template>
    <v-container class="pa-0 height-100">
        <v-row class="height-100">
            <v-col class="height-100" cols="6">
                <d-text-list class="height-100" v-model="selectedTextId"/>
            </v-col>
            <v-divider vertical/>
            <v-col cols="6">
                <d-trans-unit
                    v-if="selectedTextId"
                    :id="selectedTextId"
                    :key="`${selectedTextId}${$store.state.languageSelection.target}`"
                />
                <d-trans-unit-empty v-else/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DTextList from "@/components/DTextList";
import DTransUnit from "@/components/DTransUnit";
import DTransUnitEmpty from "@/components/DTransUnitEmpty";
export default {
    components: {DTransUnitEmpty, DTransUnit, DTextList},
    data() {
        return {
            selectedTextId: undefined,
        }
    },
    computed: {
        dataLastLoaded() {
            return this.$store.state.dataLastLoaded;
        }
    },
    watch: {
        dataLastLoaded() {
            this.selectedTextId = undefined;
        }
    }
}
</script>
