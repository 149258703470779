<template>
    <div>
        <v-form v-model="formValid">
            <v-textarea
                :value="transUnit.source"
                label="Quelltext"
                class="v-input--is-disabled"
                readonly
            />
            <v-textarea
                :value="transUnit.target"
                @input="targetText = $event"
                @keydown.enter.prevent="save"
                @keydown.enter.shift.prevent="save"
                label="Übersetzter Text"
                :rules="[v => !!v]"
            />
            <v-btn
                class="primary"
                :class="{'white--text': !darkMode, 'black--text': darkMode}"
                :disabled="!translateAvailable"
                :loading="isTranslating"
                @click="translateUnit"
            >
                <v-icon left>mdi-translate</v-icon>
                Automatisch Übersetzen
            </v-btn>
            <v-btn
                class="secondary ml-2"
                :class="{'white--text': darkMode, 'black--text': !darkMode}"
                :disabled="!formValid || !differentAsSaved"
                @click="save"
            >
                <v-icon left>mdi-content-save</v-icon>
                Speichern
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import { requestSingleTranslation } from "@/modules/requestDeepL";

export default {
    data() {
        return {
            targetText: undefined,
            formValid: true,
            isTranslating: false,
        }
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        transUnit() {
            return this.$store.state.transUnits.find(unit => unit.id === this.id);
        },
        differentAsSaved() {
            return this.targetText !== undefined && this.transUnit.target !== this.targetText;
        },
        translateAvailable() {
            return this.$store.getters.translateAvailable
                && !this.$store.state.isTranslating;
        },
        darkMode() {
            return this.$store.state.darkMode;
        },
    },
    methods: {
        save() {
            this.$store.commit('setTranslationTarget', {id: this.id, target: this.targetText});
        },
        translateUnit() {

            this.isTranslating = true;

            requestSingleTranslation(this.transUnit, this.$store.state.apiKey, this.$store.getters.requestOptions)
                .then(val => {

                    this.targetText = val;
                    this.save();
                    this.isTranslating = false;
                }).catch(err => {

                    console.error(err);
                    this.$store.commit("setErrorMessage", "Es konnte keine Verbindung zum Übersetzungsservice hergestellt werden");
                    this.isTranslating = false;
                });
        },
    },
}
</script>
