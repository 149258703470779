import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import debouncedSavingLocalForage from "@/plugins/debouncedSavingLocalForage";
import { validateTags } from "@/utils/xmlpers";

const vuexPersistence = new VuexPersistence({
    key: 'deepliff',
    storage: debouncedSavingLocalForage,
    asyncStorage: true
});

Vue.use(Vuex);

function transpose(obj) {
    const object = {};
    Object.keys(obj)
        .forEach(key => {
            const value = obj[key];
            object[value] = key;
        });
    return object;
}

const store = new Vuex.Store({
    plugins: [
        vuexPersistence.plugin
    ],
    state: {
        apiKey: '',
        fileName: "",
        fileObject: {},
        transUnits: [],
        invalidTransUnitIds: [],
        translationErrors: [],
        needEmptyTargets: false,
        ignoreTranslationErrors: false,
        dataLastLoaded: undefined,
        isTranslating: false,
        apiKeyInputEnabled: false,
        apiKeyInvalid: false,
        errorMessage: "",
        useFormality: true,
        languageSelection: {
            source: '',
            target: '',
            supportedLanguages: {
                source: {},
                target: {},
                formality: {},
            }
        },
        darkMode: false
    },
    getters: {
        supportedSourceLanguagesByName(state) {
            return transpose(state.languageSelection.supportedLanguages.source);
        },
        supportedTargetLanguagesByName(state) {
            return transpose(state.languageSelection.supportedLanguages.target);
        },
        requestOptions(state) {
            return {
                "source-language": state.languageSelection.source,
                "target-language": state.languageSelection.target,
                "formality": state.useFormality ? "prefer_more" : "prefer_less",
            };
        },
        untranslatedUnits(state) {

            return state.transUnits.filter(unit => unit.source === unit.target);
        },
        translateAvailable(state) {
            const sourceLanguage = state.languageSelection.source;
            const targetLanguage = state.languageSelection.target;

            const apiKeyEditingEnabled = state.apiKeyInputEnabled;
            const apiKeyIsPresent = state.apiKey !== '';
            const apiKeyInvalid = state.apiKeyInvalid;
            const bothLanguagesSelected = sourceLanguage !== '' && targetLanguage !== '';
            const sourceAndTargetAreNotSame = sourceLanguage !== targetLanguage;
            const parsedDataIsPresent = state.transUnits.length > 0;

            return !apiKeyEditingEnabled
                && apiKeyIsPresent
                && !apiKeyInvalid
                && bothLanguagesSelected
                && parsedDataIsPresent
                && sourceAndTargetAreNotSame
        },
    },
    mutations: {
        validateExistingState(state) {

            state.invalidTransUnitIds = state.transUnits
                .filter(v => v.type === "xml")
                .filter(w => !validateTags(w.target))
                .map(v => v.id);
        },
        setTranslationErrors(state, key) {
            state.translationErrors = key;
        },
        setNeedEmptyTargets(state, key) {
            state.needEmptyTargets = key;
        },
        setIgnoreTranslationErrors(state, key) {
            state.ignoreTranslationErrors = key;
        },
        setUseFormality(state, key) {
            state.useFormality = key;
        },
        setApiKey(state, key) {
            state.apiKey = key;
        },
        setApiKeyInvalid(state, invalid) {
            state.apiKeyInvalid = invalid;
        },
        setFileName(state, data) {
            state.fileName = data;
        },
        setFileObject(state, data) {
            state.fileObject = data;
        },
        setTransUnits(state, data) {
            state.transUnits = data;
            state.dataLastLoaded = Date.now();
        },
        setApiKeyInputEnabled(state, enabled) {
            state.apiKeyInputEnabled = enabled;
        },
        setErrorMessage(state, message) {
            state.errorMessage = message;
        },
        setLanguage(state, {type, value}) {
            state.languageSelection[type] = value;
        },
        setSupportedLanguage(state, {type, value}) {
            state.languageSelection.supportedLanguages[type] = value;
        },
        setIsTranslating(state, enabled) {
            state.isTranslating = enabled;
        },
        setTranslationTarget(state, {id, target}) {
            // no find because of performance reasons
            for(const unit of state.transUnits) {
                if(unit.id === id) {
                    unit.target = target;

                    if (unit.type === "xml" && !validateTags(target)) state.invalidTransUnitIds.push(id);
                    else state.invalidTransUnitIds.splice(state.invalidTransUnitIds.indexOf(id), 1);

                    return;
                }
            }
        },
        setDarkmode(state, enabled) {
            state.darkMode = enabled;
        },
    },
    actions: {
        setSourceLanguage({commit}, language) {
            commit('setLanguage', {type: 'source', value: language});
        },
        setTargetLanguage({commit}, language) {
            commit('setLanguage', {type: 'target', value: language});
        },
        setSupportedSourceLanguages({commit}, languages) {
            commit('setSupportedLanguage', {type: 'source', value: languages});
        },
        setSupportedTargetLanguages({commit}, languages) {
            commit('setSupportedLanguage', {type: 'target', value: languages});
        },
        setSupportedFormalityLanguages({commit}, languages) {
            commit('setSupportedLanguage', {type: 'formality', value: languages});
        },
    }
});

export default store;
