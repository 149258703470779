<template>
    <d-text-dialog
        :value="value"
        @input="$emit('input', $event)"
        title="Nicht übersetzte Elementtexte"
    >
        <ul>
            <li v-for="(el, i) in errors" class="mt-4" :key="i">
                {{ el }}
            </li>
        </ul>
    </d-text-dialog>
</template>

<script>
import DTextDialog from "@/components/DTextDialog";

export default {
    components: { DTextDialog },
    props: {
        value: [Boolean, KeyboardEvent],
    },
    computed: {
        errors() {
            return this.$store.state.translationErrors;
        },
    },
};
</script>

<style scoped>
li:first-child {
    margin-top: 0 !important;
}
</style>

