import localforage from "localforage";

const localForage = localforage.createInstance({
    name: 'deepliff',
    version: 1.0,
    storeName: 'deepliff'
});

localForage.setDriver([
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE
]).catch();

function debounce(fn, delay) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => fn(...args), delay);
    }
}

const debouncedSave = debounce((key, data) => localForage.setItem(key, data), 1000);
const debouncedSavingLocalForage = {
    getItem(key) {
        return localForage.getItem(key);
    },
    setItem(key, data) {
        debouncedSave(key, data);
    },
    removeItem(key) {
        return localForage.removeItem(key);
    },
    clear() {
        return localForage.clear();
    },
    length() {
        return localForage.length();
    },
    key(keyIndex) {
        return localForage.key(keyIndex);
    }
};

export default debouncedSavingLocalForage;
