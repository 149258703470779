<template>
    <v-col cols="6">
        <div class="d-flex">
            <v-text-field
                label="DeepL API Key"
                class="mr-3"
                :disabled="!editEnabled"
                :type="editEnabled ? 'text' : 'password'"
                :hint="hint"
                :error-messages="error"
                :value="$store.state.apiKey"
                @input="key = $event; $store.commit('setApiKeyInvalid', false)"
                @keydown.enter="toggleEdit"
                persistent-hint
            />
            <v-icon
                class="ma-auto hover pa-1"
                @click.passive="toggleEdit"
                :aria-label="editAriaLabel"
            >
                {{ icon }}
            </v-icon>
        </div>
    </v-col>
</template>

<script>
export default {
    data() {
        return {
            key: ""
        }
    },
    computed: {
        editEnabled() {
            return this.$store.state.apiKeyInputEnabled;
        },
        icon() {
            return `mdi-${ this.editEnabled ? "content-save" : "pencil" }`;
        },
        editAriaLabel() {
            return `${ this.editEnabled ? "Save" : "Edit" } API Key`;
        },
        error() {
            return this.$store.state.apiKeyInvalid && this.key.length ? "Der API Key ist ungültig" : "";
        },
        hint() {
            return this.editEnabled ? "Der API Key muss gespeichert sein, damit übersetzt werden kann" : "";
        },
    },
    methods: {
        toggleEdit() {
            this.$store.commit("setApiKeyInputEnabled", !this.$store.state.apiKeyInputEnabled);

            if (!this.editEnabled) {

                this.$store.commit("setApiKey", this.key)
            }

            this.key = this.$store.state.apiKey;
        }
    },
}
</script>
