<template>
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        @keydown.esc="$emit('input', $event)"
        max-width="70vw"
    >
        <v-card>
            <v-toolbar
                class="mb-3 primary"
                flat
                dense
            >
                <v-toolbar-title :class="{ 'white--text': !darkMode, 'black--text': darkMode }">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="font-size: larger">
                <slot />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    @click="$emit('input', false)"
                    class="white--text red mb-2"
                >Schließen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: String,
        value: Boolean,
    },
    computed: {
        darkMode() {
            return this.$store.state.darkMode;
        },
    },
};
</script>
