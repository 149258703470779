import convert from "xml-js";
import { fileReaderAsync } from "@/utils/promises";
import { first } from "@/utils/arrays";
import { filter, into } from "ramda";

/**
 * Takes an XLIFF File and converts it into Deepliff Javascript Object
 * @param file { File | Blob } - The XLIFF File to be read
 * @returns { Promise<{ fileObject: object, source: string, target: string, transUnits: object }> } - The Deepliff Javascript Object
 */
export const parseXliff = async (file) => {

    const fileString = await fileReaderAsync(file);
    const fileObject = convert.xml2js(fileString, {});

    const fileAttributes = retrieveFileAttributes(fileObject);
    const transUnitElements = getXliffElementsByName(fileObject, "trans-unit");

    return {
        fileObject,
        source: fileAttributes["source-language"],
        target: fileAttributes["target-language"],
        transUnits: convertXliffElementsToDeepliff(transUnitElements),
    };
};

/**
 * Converts Xliff Element Objects to Deepliff Objects
 * @param xliff { object } - The Xliff Elements Object
 * @returns { object } - The Deepliff Object
 */
const convertXliffElementsToDeepliff = xliff => xliff.map(value => {

    const type = getTypeFromXliff(value);
    const sourceElement = first(getXliffElementsByName(value, "source"));
    const targetElement = first(getXliffElementsByName(value, "target"));

    return {
        id: value.attributes.id,
        type,
        source: createDeepliffTextFromObject(sourceElement, type),
        target: createDeepliffTextFromObject(targetElement, type),
        rawTarget: targetElement,
    };
});

/**
 * Determines if the type attribute needs to be text or xml
 * @param obj { object } - The Xliff Element Object
 * @returns { string } - The type of the Xliff Element
 */
const getTypeFromXliff = obj => first(obj.elements).elements?.every(v => v.type === "text") ? "text" : "xml";

/**
 * Extracts XLIFF Element Objects to Text
 * @param obj { object } - The XLIFF Object
 * @param type { string } - The type of the Deepliff Object
 * @returns { string } - The extracted text
 */
const createDeepliffTextFromObject = (obj, type) => type === "text" ? first(getXliffElementsByType(obj, type)).text : convert.js2xml(obj, {});

/**
 * Retrieves the attributes of an XLIFFs File Element
 * @param obj { object } - The XLIFF File Object
 * @returns { object } - The XLIFF File Attributes
 */
const retrieveFileAttributes = obj => first(getXliffElementsByName(obj, "file"))?.attributes;

/**
 * Returns a predicate something ramda stuff thing function??
 * @param property { string } - The property to filter the value of
 * @param value { * } - The property value to filter the object for
 * @returns {*|(function(*): (*))}
 */
const filterByProperty = (property, value) => filter(element => element[property] === value);

/**
 * Returns an Array of all the elements from the xliffObject that have the specified name
 * @param xliffObject - The XLIFF Object
 * @param elementName - The name of the element to be filtered for
 * @returns { *[] }
 */
const getXliffElementsByName = (xliffObject, elementName) => into([], filterByProperty("name", elementName), getIteratorForXliff(xliffObject));

/**
 * Returns an Array of all the elements from the xliffObject that have the specified type
 * @param xliffObject - The XLIFF Object
 * @param elementType - The type of the element to be filtered for
 * @returns { *[] }
 */
const getXliffElementsByType = (xliffObject, elementType) => into([], filterByProperty("type", elementType), getIteratorForXliff(xliffObject));

/**
 * Creates an Iterator to traverse the XLIFF Object Tree
 * @param xliffObject { object } - The XLIFF Object
 * @returns { Iterable }
 */
const getIteratorForXliff = (xliffObject) => {

    return {
        [Symbol.iterator]() {

            let nodeQueue = [...xliffObject.elements] ?? [];
            let done = false, value;

            return {
                next () {

                    value = nodeQueue.shift();

                    if (value) {

                        nodeQueue = nodeQueue.concat(value.elements ?? []);
                    } else {

                        done = true;
                    }

                    return {done, value};
                },
            };
        },
    };
};
