import convert from "xml-js";
import { first } from "@/utils/arrays";
import {fixAttributeValue} from "@/utils/xmlpers";

/**
 * Creates an XLIFF Exporter Object to download a File from Deepliff Data
 * @param fileName { string } - The Original Files File Name
 * @param fileObject { object } - The Deepliff Object that should be written to the File
 * @param transUnits { *[] } - The Trans Units to override the original ones
 * @param ignoreErrors { boolean } - Ignore Errors thrown
 * @returns {{ errors: string[], download: (function(): void) }}
 */
export const exportXliff = ({ fileName, fileObject, transUnits }, ignoreErrors) => {

    const errors = setXliffElements(transUnits, ignoreErrors);

    const fileString = convert.js2xml(fileObject, {
        attributeValueFn: fixAttributeValue,
    });

    const downloadLinkElement = createDownloadLinkElement(fileName, fileString);

    return {
        download: () => downloadLinkElement.click(),
        errors,
    };
};

/**
 * Replaces the XLIFF Elements with the Trans Unit Counterparts (a bit illegal)
 * @param substituteElements { *[] } - The Elements to substitute with
 * @param ignoreErrors { boolean } - Ignore Errors thrown?
 * @returns { undefined }
 */
const setXliffElements = (substituteElements, ignoreErrors) => {

    const errors = [];

    substituteElements.forEach(element => {

        if (element.rawTarget.elements) {

            try {
                element.rawTarget.elements = element.type === "text"
                    ? [{
                        type: "text",
                        text: element.target,
                    }]
                    : first(convert.xml2js("<dummy>" + element.target + "</dummy>", { attributeValueFn: fixAttributeValue }).elements).elements;
            } catch (e) {

                if (!ignoreErrors) throw e;

                console.info(e);
                errors.push(element.source);
            }
        }
    });

    return errors;
};

/**
 * Creates a link which enables a local download of a file
 * @param fileName { string } - The Original File Name
 * @param fileContent { string } - The Content to be written into the File
 * @returns {HTMLAnchorElement} - The Download Element
 */
const createDownloadLinkElement = (fileName, fileContent) => {

    const linkElement = document.createElement("a");
    linkElement.download = fileName.replace(/\.xlf$/, "-translated.xlf");

    const data = new Blob([fileContent], {
        type: "application/x-xliff+xml",
    });

    linkElement.href = window.URL.createObjectURL(data);

    return linkElement;
};
