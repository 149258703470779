import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

export default function createVuetify(dark = false) {
    Vue.use(Vuetify);

    return new Vuetify({
        theme: {
            themes: {
                light: {
                    primary: '#570441',  // logo text color
                    secondary: '#fe9ac6' // logo image color
                },
                dark: {
                    primary: '#fe9ac6',  // logo image color
                    secondary: '#570441' // logo text color
                }
            },
            dark
        }
    });
}

