<template>
    <v-app>
        <v-app-bar
            app
            elevation="5"
        >
            <v-img
                alt="Deepliff Logo"
                class="shrink mt-1"
                contain
                :src="require('@/assets/logo-name.png')"
                height="50"
                width="100"
            />

            <v-spacer/>

            <v-hover
                v-slot="{ hover }"
            >
                <v-icon
                    class="hover pa-1 mr-2"
                    :class="{ 'red--text': hover }"
                    @click.passive="showConfirmationDialog = !showConfirmationDialog"
                >
                    mdi-delete
                </v-icon>
            </v-hover>
            <div class="d-flex">
                <v-icon left>mdi-weather-sunny</v-icon>
                <v-switch
                    class="theme-toggle-switch"
                    @change="toggleDarkmode"
                    :value="darkMode"
                />
                <v-icon right>mdi-weather-night</v-icon>
            </div>
        </v-app-bar>

        <v-main>
            <v-container class="pt-5 d-flex flex-column" :style="containerHeightStyle">
                <v-snackbar
                    top
                    timeout="10000"
                    elevation="2"
                    color="error"
                    :value="errorMessage"
                    @input="!$event && $store.commit('setErrorMessage', '')"
                    class="d-flex"
                >
                    <v-icon>mdi-alert-circle-outline</v-icon>
                    <span class="ml-2 my-auto">{{ errorMessage }}</span>
                </v-snackbar>
                <d-confirm-dialog
                    v-model="showConfirmationDialog"
                    title="Lösche lokale Daten?"
                    description="Wirklich alle lokalen Daten von Deepliff löschen?"
                    cancel-text="Abbrechen"
                    confirm-text="Löschen"
                    @confirmDeletion="deleteStorage"
                />
                <d-confirm-dialog
                    v-model="showTargetResetDialog"
                    title="Sprache geändert"
                    description="Die Zielsprache wurde geändert. Sollen bereits übersetzte Texte zurückgesetzt werden?"
                    cancel-text="Nein"
                    confirm-text="Ja"
                    invert-button-colors
                    @confirmDeletion="resetTargets"
                />
                <v-row>
                    <v-col cols="12" md="8">
                        <d-form-inputs @update:target-language="targetLanguageChanged"/>
                    </v-col>
                    <v-col cols="4" class="hidden-sm-and-down d-flex-not-important">
                        <d-translation-progress class="ml-auto"/>
                    </v-col>
                    <v-col cols="12" class="hidden-md-and-up">
                        <d-translation-progress linear/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-divider/>
                </v-row>
                <v-row class="height-100-overflow-scroll">
                    <v-col cols="12" class="height-100">
                        <d-file-data
                            v-if="$store.state.transUnits.length"
                            class="height-100"
                        />
                        <d-file-data-empty v-else/>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import DFormInputs from "@/view/DFormInputs";
import DTranslationProgress from "@/components/DTranslationProgress";
import DFileData from "@/view/DFileData";
import DConfirmDialog from "@/components/DConfirmDialog";
import DFileDataEmpty from "@/view/DFileDataEmpty";
import "@/assets/utils.scss";
import { requestSupportedLanguages } from "@/modules/requestDeepL";

export default {
    data() {
        return {
            showConfirmationDialog: false,
            showTargetResetDialog: false,
        };
    },
    components: {
        DFileDataEmpty,
        DFileData,
        DTranslationProgress,
        DFormInputs,
        DConfirmDialog,
    },
    computed: {
        containerHeightStyle() {
            const navHeight = this.$vuetify.breakpoint.smAndDown ? '56px' : '64px';
            return {
                'height': `calc(100vh - ${navHeight})`
            }
        },
        darkMode() {
            return this.$store.state.darkMode;
        },
        apiKey() {
            return this.$store.state.apiKey;
        },
        errorMessage() {
            return this.$store.state.errorMessage;
        }
    },
    watch: {
        darkMode() {
            this.$vuetify.theme.dark = this.darkMode;
        },
        apiKey() {
            this.fetchLanguages();
        }
    },
    methods: {
        deleteStorage(confirmed) {

            if (confirmed) {

                window.indexedDB.deleteDatabase("deepliff");
                window.localStorage.clear();
                window.location.reload();
            }

            this.showConfirmationDialog = false;
        },
        resetTargets(confirmed) {
            if(confirmed) {
                this.$store.state.transUnits.forEach(unit => {
                    this.$store.commit("setTranslationTarget", {id: unit.id, target: unit.source});
                });
            }

            this.showTargetResetDialog = false;
        },
        toggleDarkmode() {
            this.$store.commit('setDarkmode', !this.darkMode);
        },
        targetLanguageChanged() {
            if(this.$store.getters.untranslatedUnits.length !== this.$store.state.transUnits.length) {
                this.showTargetResetDialog = true;
            }
        },
        async fetchLanguages() {

            try {

                const sourceLanguages = await requestSupportedLanguages("source", this.$store.state.apiKey);
                const targetLanguages = await requestSupportedLanguages("target", this.$store.state.apiKey);

                const reducer = (acc, cur) => {

                    acc[cur.language] = cur.name;

                    return acc;
                };

                const formalityReducer = (acc, cur) => {

                    if (cur.supports_formality) {
                        acc[cur.language] = cur.name;
                    }

                    return acc;
                };

                await this.$store.dispatch("setSupportedSourceLanguages", sourceLanguages.reduce(reducer, {}));
                await this.$store.dispatch("setSupportedTargetLanguages", targetLanguages.reduce(reducer, {}));
                await this.$store.dispatch("setSupportedFormalityLanguages", targetLanguages.reduce(formalityReducer, {}));

                this.$store.commit("setApiKeyInvalid", false);
            } catch (e) {

                if (e.status === 403) {

                    this.$store.commit("setApiKeyInvalid", true);
                }
            }
        }
    },
    mounted() {
        window.addEventListener("keydown", e => {

            if (e.ctrlKey && e.metaKey && e.key === "Backspace") this.showConfirmationDialog = !this.showConfirmationDialog;
        });
    },
};
</script>

<style>
.theme-toggle-switch {
    transform: translate(2px, 25%);
}
.v-snack__content {
    display: flex;
}
.d-flex-not-important {
    display: flex;
}
</style>
