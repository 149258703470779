<template>
    <div class="height-100 d-flex flex-column">
        <d-text-dialog v-model="showXMLpDialog" title="Wie erkenne ich korrektes XML?">
            <p>XML-Elemente werden durch ein Tag-Paar markiert, das heißt durch einen Starttag und einen Endtag.</p>
            <p>Ein Starttag beginnt immer mit dem Zeichen "&lt;".</p>
            <p>Es folgt der Elementname (z.B. g) und optional eine Liste seiner Attribute (z.B. id="123").</p>
            <p>Mit einem "&gt;" wird der Starttag geschlossen. Ein Endtag besteht aus den Zeichen "&lt;/", dem Elementnamen und dem abschließenden "&gt;".</p>
            <p>Ein Beispiel: &lt;g id="123"&gt;Text&lt;/g&gt;</p>
            <p>Prüfe auf die korrekte Anzahl an Start- und Endtags. Diese müssen sich stets die Waage halten.</p>
            <p>Gibt es also 3 &lt;g id="123"&gt; Starttags braucht es auch <em>genau</em> 3 &lt;/g&gt; Endtags.</p>
        </d-text-dialog>
        <div>
            <div class="d-flex align-center">
                <v-text-field
                    v-model="search"
                    label="Suchen"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear.passive="search = ''"
                />
                <v-icon
                    class="ml-4 pa-1 hover"
                    :class="selectedFilter ? 'pink--text text--lighten-3' : ''"
                    @click.passive="filtersVisible = !filtersVisible"
                >
                    mdi-filter-menu
                </v-icon>
            </div>
            <v-expand-transition>
                <v-select
                    class="my-0 py-0"
                    v-model="selectedFilter"
                    v-show="filtersVisible"
                    :items="filters"
                    item-text="name"
                    label="Filter"
                    color="primary"
                    return-object
                    clearable
                    prepend-inner-icon="mdi-filter"
                />
            </v-expand-transition>
        </div>
        <div
            class="height-100-overflow-scroll"
        >
            <v-virtual-scroll
                :items="filteredItems"
                :item-height="56"
                bench="10"
            >
                <template v-slot:default="{ item }">
                    <v-list-item
                        @click="$emit('input', item.id)"
                        :class="{'v-list-item--active primary--text': item.id === value}"
                    >
                        <v-list-item-content class="text-no-wrap">{{ item.source }}</v-list-item-content>
                        <v-list-item-icon v-show="item.type === 'xml'" class="pl-2">
                            <v-icon class="lopacity">
                                mdi-xml
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon
                            @click.passive="showXMLp(item)"
                        >
                            <v-tooltip left transition="slide-x-reverse-transition">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        :class="{ 'success--text': isTranslated(item) && !isInvalid(item), 'error--text': isInvalid(item) }"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-{{ isInvalid(item) ? "close" : isTranslated(item) ? "checkbox-marked" : "checkbox-blank" }}-circle-outline
                                    </v-icon>
                                </template>
                                <span v-if="isInvalid(item)">Fehlerhaftes XML. Klicke für eine Erklärung</span>
                                <span v-else-if="isTranslated(item)">Übersetzt</span>
                                <span v-else>Noch nicht übersetzt</span>
                            </v-tooltip>
                        </v-list-item-icon>
                    </v-list-item>
                </template>
            </v-virtual-scroll>
        </div>
    </div>
</template>

<script>

import { T } from "ramda";
import DTextDialog from "@/components/DTextDialog";

export default {
    components: { DTextDialog },
    data() {
        return {
            search: "",
            selectedFilter: null,
            filtersVisible: false,
            showXMLpDialog: false,
        };
    },
    props: {
        value: {
            type: String
        }
    },
    computed: {
        items() {
            return this.$store.state.transUnits;
        },
        filteredItems() {
            return this.items
                .filter(this.selectedFilter?.fn ?? T)
                .filter(v =>
                    v.source.toLowerCase().includes(this.search.toLowerCase())
                    || v.target.toLowerCase().includes(this.search.toLowerCase())
                );
        },
        filters() {
            return [
                {
                    name: "Nur Übersetzte Elemente",
                    fn: this.isTranslated,
                },
                {
                    name: "Nur Unübersetzte Elemente",
                    fn: item => !this.isTranslated(item),
                },
                {
                    name: "Nur Elemente mit hoher Fehleranfälligkeit",
                    fn: item => item.type === "xml",
                },
                {
                    name: "Nur Fehlerhafte Elemente",
                    fn: this.isInvalid,
                },
            ];
        },
    },
    methods: {
        showXMLp(item) {

            if (this.isInvalid(item)) {

                this.showXMLpDialog = true;
            }
        },
        isTranslated(item) {

            return item.source !== item.target;
        },
        isInvalid(item) {

            return this.$store.state.invalidTransUnitIds.includes(item.id);
        }
    },
    created() {

        if (this.items.length) {

            this.$store.commit("validateExistingState");
            return;
        }

        const unwatch = this.$watch("items", (v) => {

            this.$store.commit("validateExistingState");

            if (v.length) unwatch();
        });
    },
};
</script>

<style scoped>
.lopacity { opacity: 0.8 }
</style>
