<template>
    <div class="translation-process">
        <v-progress-circular
            v-if="!linear"
            :rotate="-90"
            :size="260"
            :width="15"
            :value="translationProgress"
            aria-label="Übersetzungsfortschritt"
            :color="progressColor"
        >
            <div class="d-flex flex-column">
                <span class="text-center">{{ translationProgress }}% übersetzt</span>
                <span class="text-center font-weight-light">{{ translatedElements.length }}/{{ data.length }} Elemente</span>
            </div>
        </v-progress-circular>
        <v-progress-linear
            v-else
            :value="translationProgress"
            :color="progressColor"
            height="25"
            aria-label="Übersetzungsfortschritt"
        >
            {{ translationProgress }}% übersetzt
        </v-progress-linear>
    </div>
</template>

<script>
const colorMapping = {
    '87': 'green',
    '75': 'light-green',
    '62': 'lime',
    '50': 'yellow',
    '37': 'amber',
    '25': 'orange',
    '12': 'deep-orange',
    '0' : 'red'
};

export default {
    props: {
        linear: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        data() {
            return this.$store.state.transUnits;
        },
        translatedElements() {
            return this.data.filter(d => d.source !== d.target && !this.isInvalid(d.id));
        },
        translationProgress() {
            const percent = (this.translatedElements.length / this.data.length) * 100;
            if(isNaN(percent)) {
                return 0;
            }
            return Math.floor(percent);
        },
        progressColor() {
            const progress = this.translationProgress;
            const colorKey = Object.keys(colorMapping)
                .map(number => Number(number))
                .sort((a, b) => b - a)
                .find(number => progress >= number);

            return colorMapping[colorKey];
        }
    },
    methods: {
        isInvalid(id) {
            return this.$store.state.invalidTransUnitIds.includes(id);
        },
    },
};
</script>

<style>
.translation-process .v-progress-linear__content {
    color: black;
}

.theme--dark.v-application .translation-process .v-progress-circular__info {
    color: white;
}

.theme--light.v-application .translation-process .v-progress-circular__info {
    color: black;
}
</style>
