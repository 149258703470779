<template>
    <v-row>
        <v-col cols="12" class="d-flex justify-space-between">
            <v-switch
                @change="toggleFormality"
                :value="useFormality && supportsFormality"
                :disabled="!supportsFormality"
                label="Formelle Sprache"
            />
            <v-switch
                @change="toggleNeedEmptyTargets"
                :value="needEmptyTargets"
                label="Ohne Übersetzungen"
            />
            <v-switch
                @change="toggleIgnoreTranslationErrors"
                :value="ignoreTranslationErrors"
                label="Fehler ignorieren"
            />
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            useFormality: undefined,
            needEmptyTargets: false,
            ignoreTranslationErrors: false,
        };
    },
    computed: {
        supportsFormality() {
            return this.supportedFormalityLanguageNames.includes(this.targetLanguage);
        },
        supportedLanguages() {
            return this.$store.state.languageSelection.supportedLanguages;
        },
        supportedFormalityLanguageNames() {
            return Object.values(this.supportedLanguages.formality);
        },
        targetLanguage() {
            return this.supportedLanguages.target[this.$store.state.languageSelection.target];
        },
    },
    methods: {
        toggleFormality(event) {
            this.useFormality = event;
            this.$store.commit("setUseFormality", this.supportsFormality ? this.useFormality : undefined);
        },
        toggleNeedEmptyTargets(event) {
            this.needEmptyTargets = event;
            this.$store.commit("setNeedEmptyTargets", event);
        },
        toggleIgnoreTranslationErrors(event) {
            this.ignoreTranslationErrors = event;
            this.$store.commit("setIgnoreTranslationErrors", event);
        },
    },
};
</script>

<style scoped>

</style>
