/**
 * Promisified async version of the browsers file reader
 * @param file { File | Blob } - The file to be read
 * @returns {Promise<string | Error>}
 */
export const fileReaderAsync = (file) => new Promise((resolve, reject) => {

    if (!(file instanceof Blob)) {

        reject(new TypeError("Must be a File or Blob"));
    }

    const reader = new FileReader();

    reader.onloadend = e => resolve(e.target.result);
    reader.onerror = e => reject(new Error(`Error reading ${ file.name }: ${ e.target.result }`));

    reader.readAsText(file);
});

/**
 * Promisified version of setTimeout
 * @param ms { int } - The time in milliseconds to wait until the Promise resolves
 * @returns {Promise<undefined>}
 */
export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
