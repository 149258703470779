<template>
    <v-container class="pa-0">
        <v-row>
            <v-col cols="12" sm="6">
                <v-btn
                    @click="translateElements"
                    block
                    color="primary"
                    :class="{'black--text': darkMode, 'white--text': !darkMode}"
                    :disabled="!translateAvailable"
                    :loading="$store.state.isTranslating"
                >
                    <v-icon left>
                        mdi-translate
                    </v-icon>
                    Alle Automatisch Übersetzen
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
                <v-btn
                    @click="needEmptyTargets ? downloadEmptyFile() : downloadFile()"
                    block
                    color="secondary"
                    :class="{'black--text': !darkMode, 'white--text': darkMode}"
                >
                    <v-icon left>
                        mdi-download
                    </v-icon>
                    Herunterladen
                </v-btn>
            </v-col>
        </v-row>
        <d-translation-errors v-model="showErrorDialog"/>
    </v-container>
</template>

<script>
import { exportXliff } from "@/modules/exportXliff";
import { requestTranslations } from "@/modules/requestDeepL";
import DTranslationErrors from "@/components/DTranslationErrors";

export default {
    components: { DTranslationErrors },
    data() {
        return {
            showErrorDialog: false,
        };
    },
    computed: {
        translateAvailable() {
            return this.$store.getters.translateAvailable;
        },
        darkMode() {
            return this.$store.state.darkMode;
        },
        needEmptyTargets() {
            return this.$store.state.needEmptyTargets;
        },
        ignoreTranslationErrors() {
            return this.$store.state.ignoreTranslationErrors;
        },
    },
    methods: {
        translateElements() {

            this.$store.commit("setIsTranslating", true);
            const promises = requestTranslations(this.$store.getters.untranslatedUnits, this.$store.state.apiKey, this.$store.getters.requestOptions);

            promises.forEach(async element => {

                const response = await element.promise;
                this.$store.commit("setTranslationTarget", {
                    id: element.id,
                    target: response,
                });
            });

            Promise.all(promises)
                .then(() => this.$store.commit("setIsTranslating", false))
                .catch(() => {

                    this.$store.commit("setErrorMessage", "Es konnte keine Verbindung zum Übersetzungsservice hergestellt werden");
                    this.$store.commit("setIsTranslating", false);
                });
        },
        handleDownload(data) {
            try {

                const xliff = exportXliff(data, this.ignoreTranslationErrors);

                if (xliff.errors.length) {
                    this.$store.commit("setTranslationErrors", xliff.errors);
                    this.showErrorDialog = true;
                }

                xliff.download();
            } catch (e) {

                this.$store.commit("setErrorMessage", "Manche Texte sind defekt. Sie sind unten in der Liste mit einem roten X markiert.");
                console.error(e);
            }
        },
        downloadFile() {

            const data = {
                fileName: this.$store.state.fileName,
                fileObject: this.$store.state.fileObject,
                transUnits: this.$store.state.transUnits,
            };

            this.handleDownload(data);
        },
        downloadEmptyFile() {

            const emptyTargets = transUnits => transUnits.map(t => ({
                ...t,
                target: "",
            }));

            const data = {
                fileName: this.$store.state.fileName,
                fileObject: this.$store.state.fileObject,
                transUnits: emptyTargets(this.$store.state.transUnits),
            };

            this.handleDownload(data);
        },
    },
};
</script>
