/**
 * Returns the first element of an array
 * @param arr {*[] | string}
 * @returns {*}
 */
export const first = arr => arr?.[0];

/**
 * Returns the last element of an array
 * @param arr {*[] | string}
 * @returns {*}
 */
export const last = arr => arr[ arr.length - 1 ];
