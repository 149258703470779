import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false;

new Vue({
    store,
    vuetify: vuetify(store.state.darkMode),
    render: h => h(App)
}).$mount('#app')
