import convert from "xml-js";

export const fixAttributeValue = v => v.replaceAll(/</g, '&lt;').replaceAll(/\n/g, '&#xa;').replaceAll(/&/g, '&amp;').replaceAll(/=/g, '&#61;');

export const validateTags = v => {

    try {

        convert.xml2js(`<dummy>${ v }</dummy>`);

        return true;
    } catch (e) {

        return false;
    }
};
